import React from 'react'

import '../find-pro-hire.css'

export const HireCategories = ({ categories, filledSubCategories, redirectToDetails }) => (
    <div className="find-pro__container">
    {
        categories
        .sort((a, b) => a.category_name > b.category_name)
        .map((category, i) => (
          <div className="find-hire__categories" key={category.id}>
            <h2 className="find-hire__category-title">{category.category_name}</h2>
            {
                category.subcategories &&
                    (<div className="find-hire__sub-categories">
                        {category.subcategories.map((subcategory, j) => (
                            <span onClick={() => redirectToDetails(category.category_name, category.id, subcategory.category_name, subcategory.id)} 
                            className={`find-pro__sub-category-title ${filledSubCategories.indexOf(subcategory.id) !== -1 ? 'filled__find-a-pro-hire-link' : 'empty-a-pro-hire-link'}`} key={subcategory.id}>{subcategory.category_name}</span>))}
                    </div>)
            }
            </div>  
        ))
    }
    </div>
)